import { useSelector } from 'react-redux';

//export const BASE_URL = 'http://localhost:5050/api';
export const BASE_URL = 'https://gathrr.radarsofttech.in/api';

// Custom hook to get jwtToken
export function useJwtToken() {
    const token = useSelector((state) => state.roleReducer.jwtToken);
   return token
}
